<template>
  <fragment>
    <div class="relative flex-1" v-if="loading">
      <v-loading></v-loading>
    </div>
    <div class="relative flex-1" v-else>
      <div class="w-full bg-blue-400 text-white p-2 font-medium text-lg">
        Select Specs
      </div>
      <div class="w-full text-gray-600 pl-4 pt-2 font-medium text-lg" v-if="check(powers)">
        Power (Kw)
      </div>
      <div class="makes-container flex flex-wrap pb-2 pr-2 text-sm" v-if="check(powers)">
        <div>
          <div v-on:click="sPower=null"
               :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 font-bold cursor-pointer hover:bg-blue-100 hover:border-blue-400 hover:text-blue-500', sPower==null ? 'hover:border-blue-500 border-blue-500 bg-blue-100 text-blue-500' : 'border-gray-200 text-gray-400']">
            All
          </div>
        </div>
        <div v-for="power in _.keys(powers)" v-bind:key="power">
          <div v-on:click="sPower=power"
               :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 font-bold cursor-pointer hover:bg-blue-100 hover:border-blue-400 hover:text-blue-500 truncate', sPower==power ? 'hover:border-blue-500 border-blue-500 bg-blue-100 text-blue-500' : 'border-gray-200 text-gray-400']">
            {{ power }}
          </div>
        </div>
      </div>
      <div class="w-full text-gray-600 pl-4 pt-2 font-medium text-lg" v-if="check(fuels)">
        Fuel
      </div>
      <div class="makes-container flex flex-wrap pb-2 pr-2 text-sm" v-if="check(fuels)">
        <div>
          <div v-on:click="sFuel=null"
               :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 font-bold cursor-pointer hover:bg-blue-100 hover:border-blue-400 hover:text-blue-500', sFuel==null ? 'hover:border-blue-500 border-blue-500 bg-blue-100 text-blue-500' : 'border-gray-200 text-gray-400']">
            All
          </div>
        </div>
        <div v-for="fuel in _.keys(fuels)" v-bind:key="fuel">
          <div v-on:click="sFuel=fuel"
               :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 font-bold cursor-pointer hover:bg-blue-100 hover:border-blue-400 hover:text-blue-500 truncate', sFuel==fuel ? 'hover:border-blue-500 border-blue-500 bg-blue-100 text-blue-500' : 'border-gray-200 text-gray-400']">
            {{ fuel }}
          </div>
        </div>
      </div>
      <div class="w-full text-gray-600 pl-4 pt-2 font-medium text-lg" v-if="check(bodies)">
        Body
      </div>
      <div class="makes-container flex flex-wrap pb-2 pr-2 text-sm" v-if="check(bodies)">
        <div>
          <div v-on:click="sBody=null"
               :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 font-bold cursor-pointer hover:bg-blue-100 hover:border-blue-400 hover:text-blue-500', sFuel==null ? 'hover:border-blue-500 border-blue-500 bg-blue-100 text-blue-500' : 'border-gray-200 text-gray-400']">
            All
          </div>
        </div>
        <div v-for="body in _.keys(bodies)" v-bind:key="body">
          <div v-on:click="sbody=body"
               :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 font-bold cursor-pointer hover:bg-blue-100 hover:border-blue-400 hover:text-blue-500 truncate', sFuel==fuel ? 'hover:border-blue-500 border-blue-500 bg-blue-100 text-blue-500' : 'border-gray-200 text-gray-400']">
            {{ body }}
          </div>
        </div>
      </div>
      <div class="w-full text-gray-600 pl-4 pt-2 font-medium text-lg" v-if="check(doors)">
        Doors
      </div>
      <div class="makes-container flex flex-wrap pb-2 pr-2 text-sm" v-if="check(doors)">
        <div>
          <div v-on:click="sDoor=null"
               :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 font-bold cursor-pointer hover:bg-blue-100 hover:border-blue-400 hover:text-blue-500', sDoor==null ? 'hover:border-blue-500 border-blue-500 bg-blue-100 text-blue-500' : 'border-gray-200 text-gray-400']">
            All
          </div>
        </div>
        <div v-for="door in _.keys(doors)" v-bind:key="door">
          <div v-on:click="sDoor=door"
               :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 font-bold cursor-pointer hover:bg-blue-100 hover:border-blue-400 hover:text-blue-500 truncate', sDoor==door ? 'hover:border-blue-500 border-blue-500 bg-blue-100 text-blue-500' : 'border-gray-200 text-gray-400']">
            {{ door }}
          </div>
        </div>
      </div>
      <div class="w-full text-gray-600 pl-4 pt-2 font-medium text-lg" v-if="check(seats)">
        Seats
      </div>
      <div class="makes-container flex flex-wrap pb-2 pr-2 text-sm" v-if="check(seats)">
        <div>
          <div v-on:click="sSeat=null"
               :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 font-bold cursor-pointer hover:bg-blue-100 hover:border-blue-400 hover:text-blue-500', sSeat==null ? 'hover:border-blue-500 border-blue-500 bg-blue-100 text-blue-500' : 'border-gray-200 text-gray-400']">
            All
          </div>
        </div>
        <div v-for="seat in _.keys(seats)" v-bind:key="seat">
          <div v-on:click="sSeat=seat"
               :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 font-bold cursor-pointer hover:bg-blue-100 hover:border-blue-400 hover:text-blue-500 truncate', sSeat==seat ? 'hover:border-blue-500 border-blue-500 bg-blue-100 text-blue-500' : 'border-gray-200 text-gray-400']">
            {{ seat }}
          </div>
        </div>
      </div>
      <div class="w-full text-gray-600 pl-4 pt-2 font-medium text-lg" v-if="check(gearboxes)">
        Gearbox
      </div>
      <div class="makes-container flex flex-wrap pb-2 pr-2 text-sm" v-if="check(gearboxes)">
        <div>
          <div v-on:click="sGearbox=null"
               :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 font-bold cursor-pointer hover:bg-blue-100 hover:border-blue-400 hover:text-blue-500', sGearbox==null ? 'hover:border-blue-500 border-blue-500 bg-blue-100 text-blue-500' : 'border-gray-200 text-gray-400']">
            All
          </div>
        </div>
        <div v-for="gearbox in _.keys(gearboxes)" v-bind:key="gearbox">
          <div v-on:click="sGearbox=gearbox"
               :class="['ml-2 mt-2 px-4 py-2 rounded-lg border-2 font-bold cursor-pointer hover:bg-blue-100 hover:border-blue-400 hover:text-blue-500 truncate', sGearbox==gearbox ? 'hover:border-blue-500 border-blue-500 bg-blue-100 text-blue-500' : 'border-gray-200 text-gray-400']">
            {{ gearbox }}
          </div>
        </div>
      </div>
      <div class="w-full text-gray-600 pl-4 pt-2 font-medium text-lg">

      </div>
      <div class="makes-container flex flex-wrap p-2 pt-2 text-sm">
        <button v-on:click="select()"
                class="group relative flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
          <i class="fas fa-chevron-double-right mt-1 mr-2"></i> show Versions <span
            class="font-bold px-2 bg-blue-500 text-white ml-2 rounded">({{ count() }})</span>
        </button>
      </div>
    </div>
  </fragment>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import Loading from '@/components/loading';

export default {
  name: "Specs",
  components: {
    'v-loading': Loading,
  },
  data: function () {
    return {
      loading: true,
      versions: [],
      sPower: null,
      powers: {},
      sFuel: null,
      fuels: {},
      sDoor: null,
      doors: {},
      sBody: null,
      bodies: {},
      sSeat: null,
      seats: {},
      sGearbox: null,
      gearboxes: {},
    }
  },
  methods: {
    check(item) {
      return _.keys(item).length > 1;
    },
    filteredVersions() {
      let versions = this.versions;
      if (this.sPower !== null) versions = _.filter(versions, (version) => {
        return version.kw == this.sPower
      });
      if (this.sFuel !== null) versions = _.filter(versions, (version) => {
        return version.fuel == this.sFuel
      });
      if (this.sBody !== null) versions = _.filter(versions, (version) => {
        return version.body == this.sBody
      });
      if (this.sDoor !== null) versions = _.filter(versions, (version) => {
        return version.doors == this.sDoor
      });
      if (this.sSeat !== null) versions = _.filter(versions, (version) => {
        return version.seats == this.sSeat
      });
      if (this.sGearbox !== null) versions = _.filter(versions, (version) => {
        return version.gearbox == this.sGearbox
      });
      return versions
    },
    count() {
      return this.filteredVersions().length;
    },
    select() {
      this.$store.commit('tradein/setVersions', this.filteredVersions());
      this.$store.commit('tradein/setPart', 'select-versions');
    },
  },
  created: function () {
    this.versions = [];
    this.part = 'all';
    axios.get("/manager/fleet/eurotax/versions/", {
      params: {
        commercial: this.$store.getters['tradein/commercial'],
        modelIds: this.$store.getters['tradein/models'].join(','),
        registration: this.$store.getters['tradein/registration']
      }
    }).then(response => {
      this.versions = [];
      this.powers = {};
      this.fuels = {};
      this.bodies = {};
      this.doors = {};
      this.seats = {};
      this.gearboxes = {};
      this.sPower = null;
      this.sFuel = null;
      this.sBody = null;
      this.sDoor = null;
      this.sSeat = null;
      this.sGearbox = null;
      if (response.status === 200) this.versions = response.data;
      _.forEach(this.versions, (item) => {
        if (!_.has(this.powers, item.kw)) this.powers[item.kw] = [];
        this.powers[item.kw].push(item);
        if (!_.has(this.fuels, item.fuel)) this.fuels[item.fuel] = [];
        this.fuels[item.fuel].push(item);
        if (!_.has(this.bodies, item.body)) this.bodies[item.body] = [];
        this.bodies[item.body].push(item);
        if (!_.has(this.doors, item.doors)) this.doors[item.doors] = [];
        this.doors[item.doors].push(item);
        if (!_.has(this.seats, item.seats)) this.seats[item.seats] = [];
        this.seats[item.seats].push(item);
        if (!_.has(this.gearboxes, item.gearbox)) this.gearboxes[item.gearbox] = [];
        this.gearboxes[item.gearbox].push(item);
      });
      this.loading = false;
    });
  },

}
</script>

<style scoped>

</style>